<template>
  <div class="">
    <el-row type="flex" class="mb-3" align="middle" justify="space-between">
      <b>Danh sách quyền truy cập menu "{{ menu.title }}"</b>
    </el-row>
    <el-row type="flex" class="mb-3">
      <el-button
        v-for="mAction in menuActions"
        :key="mAction.id || mAction.action.id"
        :plain="menuAction && menuAction.action && menuAction.action.id !== mAction.action.id"
        type="info"
        size="small"
        @click="() => menuAction = mAction"
      >
        {{ mAction.action.label }}
      </el-button>
      <el-button
        v-if="menuActions.length < actions.length"
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="() => addActions(otherActions)"
      >
        Thêm quyền
      </el-button>
    </el-row>
    <div v-if="menuAction && menuAction.action && menuAction.action.id">
      <div>
        <el-row type="flex" align="middle">
          <p class="my-0">
            <b class="mr-2">Trạng thái truy cập: {{ menuAction.is_enabled ? 'Giới hạn truy cập': 'Không giới hạn' }}</b>
          </p>
          <el-switch
            :value="menuAction.is_enabled"
            :disabled="menuAction.action.key === 'GET'"
            @change="() => handleUpdateOrganizationAction()"
          />
        </el-row>
        <p v-if="!menuAction.is_enabled" class="mt-2">Tất cá các tài khoản trong tổ chức đều có thể truy cập</p>
        <p v-else class="mt-2">Chỉ những tài khoản nằm trong Whitelist mới được phép truy cập quyền này</p>
      </div>
      <div v-if="menuAction.is_enabled">
        <el-row type="flex" align="middle" justify="space-between">
          <p class="my-0"><b>Danh sách tài khoản (Whitelist)</b></p>
          <div>
            <el-select v-model="selectedUserId" filterable size="small">
              <el-option
                v-for="user in organization.users"
                :key="user.id"
                :label="user.name || user.email"
                :value="user.id"
                :disabled="menuAction.organization_action_assignees.map((a) => a.user_id).includes(user.id)"
              >
                <el-row type="flex" align="middle">
                  <el-avatar :size="24" :src="user.avatar_url">
                  </el-avatar>
                  <span class="ml-2">{{ user.name || user.email }}</span>
                </el-row>
              </el-option>
            </el-select>
            <el-button
              class="ml-2"
              type="primary"
              size="small"
              :disabled="!selectedUserId"
              @click="handleAssignUser"
            >
              Thêm
            </el-button>
          </div>
        </el-row>
        <div v-if="menuAction.organization_action_assignees.length === 0">
          Chưa có tài khoản
        </div>
        <div v-else>
          <el-tag
            v-for="(assigne, i) in menuAction.organization_action_assignees"
            :key="i"
            class="mr-2 mb-2"
            size="large"
            type="info"
          >{{ assigne.user.email }}</el-tag>
        </div>
      </div>
      <div v-if="menuAction && menuAction.action && menuAction.id && menuAction.action.key !== 'GET'">
        <el-divider class="my-2" />
        <el-row type="flex" align="middle" justify="space-between">
          <el-button plain type="danger" size="small" @click="() => handleDeleteOrganizationAction()">
            Xóa quyền
          </el-button>
          <!-- <el-button type="danger" size="small" @click="() => handleDeleteOrganizationMenu()">
            Xóa Menu
          </el-button> -->
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  createOrganizationAction,
  updateOrganizationAction,
  deleteOrganizationAction,
  createOrganizationActionAssign
} from '../../../../services/organization-action'

export default {
  name: 'OrgMenuPermission',
  props: {
    organization: Object,
    menu: Object,
    actions: Array,
    orgActions: Array,
    orgMenus: Array
  },
  data() {
    return {
      isEdit: false,
      callingAPI: false,
      selectedUserId: null,
      menuAction: {
        id: '',
        action: {},
        is_enabled: false,
        organization_action_assignees: []
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    menuActions() {
      if (!this.menu.id) {
        return this.actions.map((action) => ({
          id: '',
          action,
          organization_action_assignees: [],
          is_enabled: false
        }))
      }
      const orgMenuActions = this.orgActions.filter((a) => a.resource_id === this.menu.id)
      return orgMenuActions.map((mAction) => ({
        ...mAction,
        action: {
          ...mAction.action,
          ...this.actions.find((a) => a.id === mAction.action_id)
        }
      }))
    },
    otherActions() {
      const mActionIds = this.menuActions.map((a) => a.action.id)
      return this.actions.filter((a) => !mActionIds.includes(a.id))
    }
  },
  watch: {
    orgActions: 'loadData'
  },
  methods: {
    loadData() {
      if (this.menu.id) {
        const orgMenuActions = this.orgActions.filter((a) => a.resource_id === this.menu.id)
        if (orgMenuActions.length === 0) {
          this.addActions(this.actions)
        } else {
          this.menuAction = this.menuActions[0]
        }
      } else {
        this.menuAction = this.menuActions[0]
      }
    },
    addActions(actions, isEnabled = false) {
      const requests = actions.map((action) => {
        return createOrganizationAction({
          action_id: action.id,
          resource_id: this.menu.id,
          organization_id: this.organization.id,
          is_enabled: isEnabled
        })
      })
      Promise.all(requests).then(() => {
        this.$emit('reload')
      })
    },
    handleDeleteOrganizationAction() {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        deleteOrganizationAction(this.menuAction.id).then(() => {
          this.$emit('reload')
          this.$notify.success({
            title: 'Thông báo',
            message: 'Xóa quyền thành công.'
          })
        }).catch(() => {
          this.$notify.error({
            title: 'Thông báo',
            message: 'Không thể xóa quyền này!'
          })
        })
      })
    },
    handleUpdateOrganizationAction() {
      updateOrganizationAction({ id: this.menuAction.id, is_enabled: !this.menuAction.is_enabled }).then(() => {
        this.$emit('reload')
      })
    },
    handleAssignUser() {
      createOrganizationActionAssign({
        organization_action_id: this.menuAction.id,
        user_id: this.selectedUserId
      }).then(() => {
        this.selectedUserId = null
        this.$notify.success({
          title: 'Thông báo',
          message: 'Thêm người dùng thành công.'
        })
      }).catch(() => {
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể thêm người dùng!'
        })
      })
    },
    handleDeleteOrganizationMenu() {
      this.$emit('deleteMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
